import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import { compact, uniqBy } from 'lodash'
import React, { memo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import * as Icon from 'react-feather'
import ReactGA from 'react-ga'
import Fade from 'react-reveal/Fade'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  country?: string
  bookButtonImage?: string
  email?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix: string | null
  offersButton?: MenuItemProps[]
  offersButtonImage?: string
  menuItems: MenuItemProps[]
  phone?: string
  postalCode?: string
  navigationStatus?: boolean
  locality?: string
  siteName?: string
}

export const Navigation = memo(function Navigation({
  address,
  bookButtonImage,
  country,
  email,
  facebookURL,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  offersButton,
  offersButtonImage,
  menuItems,
  navigationStatus,
  phone,
  postalCode,
  locality,
  siteName,
}: Props) {
  const contactsRef = useRef(null)
  const anchorBookRef = useRef(null)
  const anchorOffersRef = useRef(null)
  const navigationOffersButton = compact(
    compact(offersButton).filter((t) =>
      languagePrefix !== 'it' ? t.languagePrefix === languagePrefix : 'it',
    ),
  )[0]

  return (
    <Container row>
      <LeftSide dial={4}>
        {menuItems ? (
          <Nav>
            <Fade left cascade duration={600} when={navigationStatus}>
              <ul>
                {menuItems.map((item, index) => (
                  <NavLi key={index}>
                    <MenuItem {...item} />
                  </NavLi>
                ))}
              </ul>
            </Fade>
          </Nav>
        ) : null}
      </LeftSide>
      <RightSide dial={5}>
        {IBEID ? (
          <CTA
            className={navigationStatus ? 'visible' : undefined}
            ref={anchorBookRef}
            href={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
              languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
            }`}
            rel="noopener"
            target="_blank"
          >
            {bookButtonImage ? (
              <BackgroundImage
                className="menu-anchor-image"
                style={{ backgroundImage: `url(${bookButtonImage})` }}
              />
            ) : null}
            <AnchorLabel>{useVocabularyData('book', languageCode)}</AnchorLabel>
          </CTA>
        ) : null}
        <Anchor
          className={navigationStatus ? 'visible' : undefined}
          to={navigationOffersButton ? navigationOffersButton.url : '/'}
          ref={anchorOffersRef}
        >
          {navigationOffersButton && offersButtonImage ? (
            <>
              <BackgroundImage
                className="menu-anchor-image"
                style={{ backgroundImage: `url(${offersButtonImage})` }}
              />
              <AnchorLabel>{navigationOffersButton.title}</AnchorLabel>
            </>
          ) : null}
        </Anchor>
        <Contacts
          className={navigationStatus ? 'visible' : undefined}
          ref={contactsRef}
          row
          space="between"
        >
          <Info>
            {siteName ? <SiteName>{siteName}</SiteName> : null}
            {address ? (
              <Address>{`${address} - ${postalCode} ${locality} ${country}`}</Address>
            ) : null}
            {phone ? (
              <Phone
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    ReactGA.event({
                      category: 'Website',
                      action: 'Click',
                      label: 'Mobile Phone',
                    })
                  }
                }}
              >
                {phone}
              </Phone>
            ) : null}
            {email ? (
              <Email
                href={`mailto:${email}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Website',
                    action: 'Click',
                    label: 'Email address',
                  })
                }}
              >
                {email}
              </Email>
            ) : null}
          </Info>
          <Social dial={5} row>
            {facebookURL ? (
              <SocialItem
                aria-label="Facebook"
                href={facebookURL}
                rel="noreferrer"
                target="_blank"
              >
                <Icon.Facebook />
              </SocialItem>
            ) : null}
            {instagramURL ? (
              <SocialItem
                aria-label="Instagram"
                href={instagramURL}
                rel="noreferrer"
                target="_blank"
              >
                <Icon.Instagram />
              </SocialItem>
            ) : null}
          </Social>
        </Contacts>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 767px) {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 4rem 10.833vw;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    background: none;
    padding: 3.75rem 1.5rem 0;
    text-align: center;
  }
`

const Nav = styled.nav`
  @media (max-width: 767px) {
    margin: auto;
  }
`

const NavLi = styled.li`
  margin-top: 3.3vh;
  &:first-of-type {
    margin-top: 0;
  }
  &.is-active {
    a {
      color: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }

  @media (max-width: 767px) {
    margin-top: 2.8vh;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  height: 100%;
  padding: 0 8.958vw;

  @media (max-width: 1199px) {
    padding: 0 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-top: 3rem;
    padding: 0 1.5rem 3rem;
  }
`

const AnchorStyle = css`
  width: 100%;
  height: 35vh;
  max-height: 17.5rem;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transform: scale(0.8);
  transition: 0.4s ease-out;
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
  &:hover {
    &:before {
      opacity: 0.6;
    }
    .menu-anchor-image {
      transform: scale(1);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${theme.colors.variants.neutralDark2};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.2s ease-out;
  }

  @media (max-height: 1199px) {
    height: 30vh;
  }

  @media (max-width: 767px) {
    height: 20vh;
    margin-top: 1.5rem;
    .menu-anchor-image {
      transform: scale(1);
    }
  }
`

const Anchor = styled(Link)`
  ${AnchorStyle}
  transition-delay: .3s;
`

const CTA = styled.a`
  ${AnchorStyle}
`

const AnchorLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const BackgroundImage = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.2s ease-out;
`

const Contacts = styled(FlexBox)`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.375rem;
  opacity: 0;
  margin-top: 7vh;
  transition: 0.4s ease-out;
  &.visible {
    opacity: 1;
    transition-delay: 0.6s;
  }

  @media (max-width: 767px) {
    margin-top: 3rem;
  }
`

const Info = styled.div``

const SiteName = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Address = styled.div``

const Phone = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.a`
  margin-left: 0.75rem;
  &:hover {
    text-decoration: underline;
  }
`

const Social = styled(FlexBox)``

const SocialItem = styled.a`
  margin-left: 0.75rem;
  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
    height: 22px;
    transition: 0.3s ease-out;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) =>
        theme.colors.getHoverColor(`${theme.colors.variants.primaryDark1}`)};
    }
  }
`
